import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { SparkProvider } from "@spark-web/core";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { Auth0Provider } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";
import AnalyticsProvider from "./components/AnalyticsProvider.tsx";
import { Provider } from "react-redux";
import store from "./store/index.ts";

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID,
    options: {
      inspectors: [
        {
          type: "flag-used",
          name: "dd-inspector",
          method: (key: string, detail) => {
            datadogRum.addFeatureFlagEvaluation(key, detail.value);
          },
        },
      ],
    },
  });
  ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
      <LDProvider>
        <SparkProvider>
          <Auth0Provider
            domain={import.meta.env.VITE_AUTH0_DOMAIN}
            clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
            authorizationParams={{
              screen_hint: "signup",
              redirect_uri: window.location.origin,
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
              scope: "openid profile email phone read:installer-invitations",
              connection: "sms", // custom-sms-gateway
            }}
          >
            <AnalyticsProvider>
              <Provider store={store}>
                <App />
              </Provider>
            </AnalyticsProvider>
          </Auth0Provider>
        </SparkProvider>
      </LDProvider>
    </React.StrictMode>
  );
})();
