/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format. */
  DateTimeISO: { input: any; output: any; }
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type Installer = {
  __typename?: 'Installer';
  _count?: Maybe<InstallerCount>;
  approved?: Maybe<Scalars['DateTimeISO']['output']>;
  approvedBy?: Maybe<Scalars['String']['output']>;
  australianBusinessNumber: Scalars['String']['output'];
  email: Scalars['String']['output'];
  emailVerified: Scalars['Boolean']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  mobileNumber: Scalars['String']['output'];
  saaNumber: Scalars['String']['output'];
  tradingName: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type InstallerCount = {
  __typename?: 'InstallerCount';
  vendors: Scalars['Int']['output'];
};


export type InstallerCountVendorsArgs = {
  where?: InputMaybe<InstallerInvitationWhereInput>;
};

export type InstallerInvitation = {
  __typename?: 'InstallerInvitation';
  createdAt: Scalars['DateTimeISO']['output'];
  expiredAt: Scalars['DateTimeISO']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  mobile: Scalars['String']['output'];
  status: InvitationStatus;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type InstallerInvitationCreateInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  mobile: Scalars['String']['input'];
  vendorId: Scalars['ID']['input'];
};

export type InstallerInvitationOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  expiredAt?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  installer?: InputMaybe<InstallerOrderByWithRelationInput>;
  lastName?: InputMaybe<SortOrder>;
  mobile?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  vendor?: InputMaybe<InstallerVendorOrderByWithRelationInput>;
};

export enum InstallerInvitationScalarFieldEnum {
  CreatedAt = 'createdAt',
  ExpiredAt = 'expiredAt',
  FirstName = 'firstName',
  Id = 'id',
  InstallerId = 'installerId',
  LastName = 'lastName',
  Mobile = 'mobile',
  UpdatedAt = 'updatedAt',
  VendorId = 'vendorId'
}

export type InstallerInvitationWhereInput = {
  AND?: InputMaybe<Array<InstallerInvitationWhereInput>>;
  NOT?: InputMaybe<Array<InstallerInvitationWhereInput>>;
  OR?: InputMaybe<Array<InstallerInvitationWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiredAt?: InputMaybe<DateTimeFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  installer?: InputMaybe<InstallerNullableRelationFilter>;
  lastName?: InputMaybe<StringFilter>;
  mobile?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vendor?: InputMaybe<InstallerVendorRelationFilter>;
};

export type InstallerInvitationWhereUniqueInput = {
  AND?: InputMaybe<Array<InstallerInvitationWhereInput>>;
  NOT?: InputMaybe<Array<InstallerInvitationWhereInput>>;
  OR?: InputMaybe<Array<InstallerInvitationWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiredAt?: InputMaybe<DateTimeFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  installer?: InputMaybe<InstallerNullableRelationFilter>;
  lastName?: InputMaybe<StringFilter>;
  mobile?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vendor?: InputMaybe<InstallerVendorRelationFilter>;
  vendorId_installerId?: InputMaybe<InvitationVendorIdInstallerIdCompoundUniqueInput>;
};

export type InstallerNullableRelationFilter = {
  is?: InputMaybe<InstallerWhereInput>;
  isNot?: InputMaybe<InstallerWhereInput>;
};

export type InstallerOrderByWithRelationInput = {
  approved?: InputMaybe<SortOrderInput>;
  approvedBy?: InputMaybe<SortOrderInput>;
  australianBusinessNumber?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  emailVerified?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  mobileNumber?: InputMaybe<SortOrder>;
  saaNumber?: InputMaybe<SortOrder>;
  tradingName?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type InstallerVendor = {
  __typename?: 'InstallerVendor';
  _count?: Maybe<InstallerVendorCount>;
  abn: Scalars['String']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  legalName: Scalars['String']['output'];
  portalId: Scalars['String']['output'];
  tradingName: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type InstallerVendorCount = {
  __typename?: 'InstallerVendorCount';
  installers: Scalars['Int']['output'];
};


export type InstallerVendorCountInstallersArgs = {
  where?: InputMaybe<InstallerInvitationWhereInput>;
};

export type InstallerVendorOrderByWithRelationInput = {
  abn?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  legalName?: InputMaybe<SortOrder>;
  portalId?: InputMaybe<SortOrder>;
  tradingName?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type InstallerVendorRelationFilter = {
  is?: InputMaybe<InstallerVendorWhereInput>;
  isNot?: InputMaybe<InstallerVendorWhereInput>;
};

export type InstallerVendorWhereInput = {
  AND?: InputMaybe<Array<InstallerVendorWhereInput>>;
  NOT?: InputMaybe<Array<InstallerVendorWhereInput>>;
  OR?: InputMaybe<Array<InstallerVendorWhereInput>>;
  abn?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  legalName?: InputMaybe<StringFilter>;
  portalId?: InputMaybe<StringFilter>;
  tradingName?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InstallerWhereInput = {
  AND?: InputMaybe<Array<InstallerWhereInput>>;
  NOT?: InputMaybe<Array<InstallerWhereInput>>;
  OR?: InputMaybe<Array<InstallerWhereInput>>;
  approved?: InputMaybe<DateTimeNullableFilter>;
  approvedBy?: InputMaybe<StringNullableFilter>;
  australianBusinessNumber?: InputMaybe<StringFilter>;
  email?: InputMaybe<StringFilter>;
  emailVerified?: InputMaybe<BoolFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringFilter>;
  mobileNumber?: InputMaybe<StringFilter>;
  saaNumber?: InputMaybe<StringFilter>;
  tradingName?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export enum InvitationStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Open = 'OPEN'
}

export type InvitationVendorIdInstallerIdCompoundUniqueInput = {
  installerId: Scalars['String']['input'];
  vendorId: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createInstallerInvitation: InstallerInvitation;
};


export type MutationCreateInstallerInvitationArgs = {
  input: InstallerInvitationCreateInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export type Query = {
  __typename?: 'Query';
  installer: Installer;
  installerInvitations: Array<InstallerInvitation>;
  installerProfile?: Maybe<Installer>;
  installerVendors: Array<InstallerVendor>;
  installers: Array<Installer>;
};


export type QueryInstallerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInstallerInvitationsArgs = {
  cursor?: InputMaybe<InstallerInvitationWhereUniqueInput>;
  distinct?: InputMaybe<Array<InstallerInvitationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InstallerInvitationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InstallerInvitationWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type InstallersQueryVariables = Exact<{ [key: string]: never; }>;


export type InstallersQuery = { __typename?: 'Query', installers: Array<{ __typename?: 'Installer', id: string, firstName: string, lastName: string, saaNumber: string, australianBusinessNumber: string, tradingName: string, mobileNumber: string, email: string, approved?: any | null, approvedBy?: string | null, userId: string }> };

export type ProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileQuery = { __typename?: 'Query', installerProfile?: { __typename?: 'Installer', id: string, firstName: string, lastName: string, saaNumber: string, australianBusinessNumber: string, tradingName: string, mobileNumber: string, email: string, approved?: any | null, approvedBy?: string | null, userId: string } | null };


export const InstallersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Installers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"installers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"saaNumber"}},{"kind":"Field","name":{"kind":"Name","value":"australianBusinessNumber"}},{"kind":"Field","name":{"kind":"Name","value":"tradingName"}},{"kind":"Field","name":{"kind":"Name","value":"mobileNumber"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"approved"}},{"kind":"Field","name":{"kind":"Name","value":"approvedBy"}},{"kind":"Field","name":{"kind":"Name","value":"userId"}}]}}]}}]} as unknown as DocumentNode<InstallersQuery, InstallersQueryVariables>;
export const ProfileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Profile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"installerProfile"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"saaNumber"}},{"kind":"Field","name":{"kind":"Name","value":"australianBusinessNumber"}},{"kind":"Field","name":{"kind":"Name","value":"tradingName"}},{"kind":"Field","name":{"kind":"Name","value":"mobileNumber"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"approved"}},{"kind":"Field","name":{"kind":"Name","value":"approvedBy"}},{"kind":"Field","name":{"kind":"Name","value":"userId"}}]}}]}}]} as unknown as DocumentNode<ProfileQuery, ProfileQueryVariables>;