import { GraphQLClient } from "graphql-request";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { ProfileQuery } from "@installer/graphql/graphql";
import { profileQuery } from "@installer/frontend/queries";
import ButtonWrapper from "./layout/ButtonWrapper";
import { Spinner } from "@spark-web/spinner";
import { Button } from "@spark-web/button";
import { Stack } from "@spark-web/stack";
import { Heading } from "@spark-web/heading";
import { CheckCircleIcon, XCircleIcon } from "@spark-web/icon";
import UnderConstruction from "./under-construction/UnderConstruction";
import { checkInvitation } from "../api";

export default function Registration({ gqlClient }: { gqlClient: GraphQLClient }) {
  const { user, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const {
    data: profileData,
    error: profileError,
    isLoading: profileLoading,
  } = useQuery<ProfileQuery>({
    queryKey: ["installerProfile"],
    queryFn: async () => await gqlClient.request(profileQuery),
  });

  if (!user) {
    throw new Error("User not authenticated");
  }

  const mobile = user.phone_number;

  const {
    data: invitationData,
    error: invitationError,
    isLoading: invitationLoading,
  } = useQuery({
    queryKey: ["installerInvitations"],
    queryFn: async () => {
      return await checkInvitation(getAccessTokenSilently);
    },
    retry: 1,
  });

  return (
    <Stack height="full" gap="large" width="full" align="center">
      {(profileLoading || invitationLoading) && <Spinner size="xsmall" tone="primary" />}
      {profileData?.installerProfile && <UnderConstruction></UnderConstruction>}
      {profileData?.installerProfile === null && invitationData?.length === 0 && (
        <>
          <XCircleIcon size="large" tone="caution" />
          <Heading level="2" align="center">
            Sorry, you need an invitation
          </Heading>
        </>
      )}
      {profileData?.installerProfile === null && invitationData && invitationData.length > 0 && mobile && (
        <>
          <CheckCircleIcon size="large" tone={"primaryHover"} />
          <Heading level="2" align="center" title={mobile}>
            Mobile verified
          </Heading>
          <ButtonWrapper>
            <Button
              onClick={() => {
                navigate("/verify-saa");
              }}
            >
              Continue
            </Button>
          </ButtonWrapper>
        </>
      )}
      {(profileError || invitationError || !mobile) && (
        <>
          <XCircleIcon size="large" tone="caution" />
          <Heading level="2" align="center">
            Error encountered
          </Heading>
        </>
      )}
    </Stack>
  );
}
